<template>
  <section class="mainHome" v-if="(!$starting && $user) || hasFeedComponent">
    <HomeFeed />
  </section>
  <section class="mainHome" v-else-if="!$starting">
    <HomePublic />
    <AgeAdvice v-if="!adultConfirm && !adult" is_home="true" @accept="accept" @cancel="cancel" />
  </section>
</template>

<script>
import feed from "@/components/feed/feed.js";
export default {
  mixins: [feed],
  components: {
    HomeFeed: () => import("../components/home/HomeFeed.vue"),
    HomePublic: () => import("../components/home/HomePublic.vue"),
    AgeAdvice: () => import("../components/landing/AgeAdvice.vue"),
  },
  data: function() {
    return {
      adult: null,
    };
  },
  methods: {
    accept: function() {
      localStorage.setItem("adult_accepted", true);
      this.adult = true;
    },
    cancel: function() {
      top.location.href = "http://www.google.com";
    },
  },
  computed: {
    adultConfirm: function() {
      return localStorage.getItem("adult_accepted");
    },
  },
};
</script>
